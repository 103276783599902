<script>
  import { differenceInDays, startOfDay } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { getContext } from "svelte";
  import {
    Render,
    Subscribe,
    createRender,
    createTable,
  } from "svelte-headless-table";
  import { addColumnFilters, addSortBy } from "svelte-headless-table/plugins";

  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import desiredDateTime from "~/libs/desiredDateTime";
  import { searchResultData } from "~/libs/stores";
  import {
    displayPostcodeFormat,
    formatStringDate,
    formatUtcToJst,
  } from "~/libs/utils";
  import { matchFilter, textIncludeFilter } from "~/pages/Search/filters";
  import SearchResultDetailButton from "~/pages/Search/SearchResultDetailButton.svelte";
  import SearchResultTableStatus from "~/pages/Search/SearchResultTableStatus.svelte";
  import SearchResultTableStatusIcon from "~/pages/Search/SearchResultTableStatusIcon.svelte";
  import SelectFilter from "~/pages/Search/SelectFilter.svelte";
  import TextFilter from "~/pages/Search/TextFilter.svelte";

  /** @type {Array<import("~/libs/backendApi").SearchedShipment>} */
  export let results;
  (() => {
    searchResultData.set(results);
  })();

  /** @type {Map<number, object>} センターIDをキーとしたセンター情報のマップ*/
  export let centersMap;

  /** @type {number} */
  export let filterResultsNum;

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const table = createTable(searchResultData, {
    sort: addSortBy({ toggleOrder: ["asc", "desc"] }),
    filter: addColumnFilters(),
  });

  const columns = table.createColumns(
    (() => {
      const columns = [
        table.column({
          header: "",
          id: "statusIcon",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(SearchResultTableStatusIcon, {
              record: dataCell.value,
            }),
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "",
          id: "detailButton",
          accessor: (item) => item,
          cell: (dataCell) =>
            createRender(SearchResultDetailButton, {
              record: dataCell.value,
            }),
          plugins: {
            sort: { disable: true },
          },
        }),
        table.column({
          header: "送り状番号",
          id: "trackingNumber",
          accessor: (item) => item.trackingNumber ?? "",
        }),
        table.column({
          header: "配送／返品ステータス",
          id: "status",
          accessor: (item) => item.shippingAndReturnStatus ?? "",
          plugins: {
            filter: {
              fn: matchFilter,
              render: ({ filterValue, preFilteredValues }) =>
                createRender(SelectFilter, {
                  filterValue,
                  preFilteredValues,
                  results,
                  columnId: "status",
                }),
            },
          },
        }),
      ];
      if (!userContext.hasEcAdminRole()) {
        columns.push(
          table.column({
            header: "EC事業者名",
            id: "EcCompanyName",
            accessor: (item) => item.customerName ?? "",
          }),
        );
      }
      columns.push(
        table.column({
          header: "注文番号",
          id: "customerOrderId",
          accessor: (item) => item.customerOrderId ?? "",
        }),
        table.column({
          header: "届け先郵便番号",
          id: "receiverPostcode",
          accessor: (item) =>
            displayPostcodeFormat(item.receiverPostcode) ?? "",
        }),
        table.column({
          header: "届け先住所1",
          id: "receiverAddress1",
          accessor: (item) => item.receiverAddress1 ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: "",
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }),
            },
          },
        }),
        table.column({
          header: "届け先住所2",
          id: "receiverAddress2",
          accessor: (item) => item.receiverAddress2 ?? "",
        }),
        table.column({
          header: "届け先住所（訂正後）",
          id: "correctedReceiverAddress",
          accessor: (item) => item.correctedReceiverAddress ?? "",
          plugins: {
            filter: {
              fn: textIncludeFilter,
              initialFilterValue: "",
              render: ({ filterValue, values }) =>
                createRender(TextFilter, {
                  filterValue,
                  values,
                }),
            },
          },
        }),
        table.column({
          header: "届け先名",
          id: "receiverName",
          accessor: (item) => item.receiverName ?? "",
        }),
        table.column({
          header: "個数",
          id: "quantity",
          accessor: (item) => item.numberOfPackages ?? "1",
        }),
        table.column({
          header: "発行日時",
          id: "releasedAt",
          accessor: (item) =>
            formatUtcToJst(item.releasedAt, "yyyy/MM/dd(E) HH:mm", {
              locale: localeJa,
            }) ?? "",
        }),
      );
      if (!userContext.hasEcAdminRole()) {
        columns.push(
          table.column({
            header: "荷受け登録場所",
            id: "relayLocationId",
            accessor: (item) =>
              Number.isInteger(item.inTransitLocationId)
                ? (centersMap.get(item.inTransitLocationId)?.name ?? "該当なし")
                : "未登録",
          }),
          table.column({
            header: "配達希望日時",
            id: "desiredDate",
            accessor: (
              /** @type {import("~/libs/backendApi").SearchedShipment} */ item,
            ) => {
              const dateAndTimeFrame = desiredDateTime
                .resolve(item)
                .find(
                  (e) => e.timeFrame !== desiredDateTime.UNRECEIVABLE_TIME_SLOT,
                );
              if (dateAndTimeFrame) {
                // 日付部分のフォーマット
                let formattedDate = "日付指定なし";
                if (dateAndTimeFrame.date) {
                  formattedDate = formatStringDate(
                    dateAndTimeFrame.date,
                    "yyyy/MM/dd(E)",
                    {
                      locale: localeJa,
                    },
                  );
                }
                // 時間部分のフォーマット
                let formattedTime = "時間指定なし";
                if (/^\d{4}$/.test(dateAndTimeFrame.timeFrame)) {
                  const timeRangeBegin = `${dateAndTimeFrame.timeFrame.substring(0, 2)}:00`;
                  const timeRangeEnd = `${dateAndTimeFrame.timeFrame.substring(2)}:00`;
                  formattedTime = `${timeRangeBegin}〜${timeRangeEnd != "00:00" ? timeRangeEnd : ""}`;
                }
                return `${formattedDate} ${formattedTime}`;
              } else {
                return "日時指定なし";
              }
            },
          }),
          table.column({
            header: "集荷日時（経過日数）",
            id: "inTransitAt",
            accessor: (item) => item.inTransitAt ?? "未登録",
          }),
          table.column({
            header: "配達試行回数",
            id: "numberOfDeliveryAttempts",
            accessor: (item) => item.numberOfDeliveryAttempts ?? "0",
          }),
          table.column({
            header: "代引金額",
            id: "cashOnDeliveryAmount",
            accessor: (item) => item.cashOnDeliveryAmount ?? 0,
          }),
          table.column({
            header: "宅配ドライバー名",
            id: "driverDisplayName",
            accessor: (item) => item.driverDisplayName ?? "未登録",
            plugins: {
              filter: {
                fn: matchFilter,
                render: ({ filterValue, preFilteredValues }) =>
                  createRender(SelectFilter, {
                    filterValue,
                    preFilteredValues,
                    results,
                    columnId: "driverDisplayName",
                  }),
              },
            },
          }),
        );
      }
      if (userContext.hasEcAdminRole()) {
        columns.push(
          table.column({
            header: "代引金額",
            id: "cashOnDeliveryAmount",
            accessor: (item) => item.cashOnDeliveryAmount ?? 0,
          }),
        );
      }
      if (userContext.hasShippingPartnerAdminRole()) {
        columns.push(
          table.column({
            header: "宅配事業者管理者専用メモ欄",
            id: "shippingPartnerInternalMessage",
            accessor: (item) => item.shippingPartnerInternalMessage ?? "",
            plugins: {
              filter: {
                fn: textIncludeFilter,
                initialFilterValue: "",
                render: ({ filterValue, values }) =>
                  createRender(TextFilter, {
                    filterValue,
                    values,
                  }),
              },
            },
          }),
        );
      }
      return columns;
    })(),
  );

  const { headerRows, rows, tableAttrs, tableHeadAttrs, tableBodyAttrs } =
    table.createViewModel(columns);

  $: filterResultsNum = $rows.length;

  /**
   * 荷受けからの経過日数を算出する。
   * @param {string} inputDay
   * @returns {number}
   */
  function calculateDaysFromReleasedAt(inputDay) {
    const now = new Date();
    const inTransitAt = new Date(inputDay);
    const startOfNow = startOfDay(now);
    const startOfInTransitAt = startOfDay(inTransitAt);
    const daysPassed = differenceInDays(startOfNow, startOfInTransitAt);
    return daysPassed;
  }
</script>

<div class="mdc-data-table">
  <div class="mdc-data-table__table-container">
    <table class="mdc-data-table__table" {...$tableAttrs}>
      <thead {...$tableHeadAttrs}>
        {#each $headerRows as headerRow (headerRow.id)}
          <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr class="mdc-data-table__header-row" {...rowAttrs}>
              {#each headerRow.cells as cell (cell.id)}
                <Subscribe
                  attrs={cell.attrs()}
                  let:attrs
                  props={cell.props()}
                  let:props
                >
                  <th
                    class="mdc-data-table__header-cell"
                    {...attrs}
                    style={cell.id === "statusIcon" ||
                    cell.id === "detailButton"
                      ? ""
                      : "cursor: pointer;"}
                    on:click={props.sort.toggle}
                  >
                    <div class="th-item">
                      <Render of={cell.render()} />
                      {#if props.sort.order === "asc"}
                        <span class="material-icons">arrow_upward</span>
                      {:else if props.sort.order === "desc"}
                        <span class="material-icons">arrow_downward</span>
                      {/if}
                    </div>
                    {#if props.filter?.render}
                      <div class="filter-area">
                        <Render of={props.filter.render} />
                      </div>
                    {/if}
                  </th>
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </thead>
      <tbody class="mdc-data-table__content" {...$tableBodyAttrs}>
        {#if $rows.length === 0}
          <tr class="mdc-data-table__row">
            <td class="mdc-data-table__cell no_data_note">
              該当するデータがありません
            </td>
          </tr>
        {:else}
          {#each $rows as row (row.id)}
            <Subscribe rowAttrs={row.attrs()} let:rowAttrs>
              <tr class="mdc-data-table__row" {...rowAttrs}>
                {#each row.cells as cell (cell.id)}
                  <Subscribe attrs={cell.attrs()} let:attrs>
                    <td
                      class="mdc-data-table__cell"
                      class:numericalValue={cell.id === "inTransitAt" ||
                        cell.id === "numberOfDeliveryAttempts" ||
                        cell.id === "cashOnDeliveryAmount" ||
                        cell.id === "quantity"}
                      {...attrs}
                    >
                      {#if cell.id === "status"}
                        <SearchResultTableStatus
                          status={cell.render()}
                          hasShippingPartnerAdminRole={userContext.hasShippingPartnerAdminRole()}
                        />
                      {:else if cell.id === "quantity"}
                        {`${cell.render()}個`}
                      {:else if cell.id === "inTransitAt"}
                        {cell.render() !== "未登録"
                          ? `${formatStringDate(
                              cell.render(),
                              "yyyy/MM/dd(E) HH:mm",
                              {
                                locale: localeJa,
                              },
                            )} (${calculateDaysFromReleasedAt(cell.render())}日経過)`
                          : cell.render()}
                      {:else if cell.id === "numberOfDeliveryAttempts"}
                        {`${cell.render()}回`}
                      {:else if cell.id === "cashOnDeliveryAmount"}
                        {cell.render() !== "0"
                          ? `${Number(cell.render()).toLocaleString()}円`
                          : ""}
                      {:else}
                        <Render of={cell.render()} />
                      {/if}
                    </td>
                  </Subscribe>
                {/each}
              </tr>
            </Subscribe>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .mdc-data-table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 220px);
    overflow-x: auto;
    overflow-y: visible;
  }

  .mdc-data-table__table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  th {
    background-color: #eaf5ff;
  }

  th {
    vertical-align: middle;
    font-size: small;

    .th-item {
      display: flex;
      position: relative;

      span {
        position: relative;
        margin-left: 3px;
        top: 3px;
        font-size: 18px;
        color: #5c5c5c;
      }
    }
  }

  td {
    vertical-align: middle;
    font-size: small;
  }

  tr th:nth-child(1),
  tr td:nth-child(1) {
    width: 1px;
    padding-left: 4px;
    padding-right: 0;
  }
  .numericalValue {
    text-align: right;
  }

  .no_data_note {
    text-align: center;
    padding: 10px;
  }
</style>
